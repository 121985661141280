import * as React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"
import StaffingPageContent from "../../components/Staffing"

export default class NotFoundPage extends React.Component {

    componentDidMount(){
        
    }


    render() {
        return (
            <Layout>
                <SEO title="Staffing" />
                <StaffingPageContent/>
            </Layout>
        )
    }
}