// extracted by mini-css-extract-plugin
export const main_container = "index-module--main_container--27Irp";
export const title = "index-module--title--ZeaTO";
export const option_main_container = "index-module--option_main_container--g-eqO";
export const option_text_container = "index-module--option_text_container--2eQLk";
export const option_img_container = "index-module--option_img_container--1Jj4I";
export const subtitle = "index-module--subtitle--17fh1";
export const contact_container = "index-module--contact_container--1yFjQ";
export const what_we_do = "index-module--what_we_do--EzW-z";
export const services_container = "index-module--services_container--y10YR";
export const services = "index-module--services--h3Xzj";
export const service = "index-module--service--34foS";
export const wiggleRight = "index-module--wiggle-right--2EVDa";
export const find_out_link = "index-module--find_out_link--HWnL3";
export const bouncy = "index-module--bouncy--LlzAp";
export const pop = "index-module--pop--2Hcj9";