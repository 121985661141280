import * as React from "react";
import ContactForm from "@components/ContactForm";
import * as styles from './index.module.scss';
import reporting from "@images/apps/reporting_orange.svg";
import cutting_edge from "@images/staffing/cutting_edge.svg";
import flexible from "@images/staffing/flexible.svg";

import analysts from "@images/about_us/analysts.svg";
import architects from "@images/about_us/architects.svg";
import designers from "@images/about_us/designers.svg";
import developers from "@images/about_us/developers.svg";
import marketers from "@images/about_us/marketers.svg";
import engineers from "@images/about_us/engineers.svg";

import websites from "@images/index_page/icon_websites.svg";
import apps from "@images/index_page/icon_apps.svg";
import engagement from "@images/index_page/icon_engagement.svg";
import eCommerce from "@images/index_page/icon_e_commerce.svg";

import logo1 from "@images/about_us/logo_1.svg";
import logo2 from "@images/about_us/logo_2.svg";
import usMap from "@images/about_us/us_map.svg";

import teamImg from "@images/graphics/team_orange.svg";
import { Link } from "@reach/router";

const IMAGES = {
  "developers": developers,
  "architects": architects,
  "engineers": engineers,
  "designers": designers,
  "analysts": analysts,
  "marketers": marketers,
  "engagement": engagement,
  "apps": apps,
  "websites": websites,
  "eCommerce": eCommerce
}

class StaffingPageContent extends React.Component {


    render() {
        return (
            <div className={`container ${styles['main_container']}`}>
                <section style={{ marginTop: '8em' }}>
                    <h2 className={styles['title']}>Staffing</h2>
                    <div className={styles['option_main_container']}>
                        <div className={styles['option_text_container']}>
                            <h2>Convenient</h2>
                            <p>Easy, convenient, low-fee developers for every business need. Magyk brings the technical expertise to support your vision and our 15% staffing fee is much lower than that of other agencies. Staffing with Magyk makes hiring highly skilled developers easy and accessable for every business.</p>
                        </div>
                        <div className={styles['option_img_container']}>
                            <img src={reporting} />
                        </div>
                    </div>
                    <div className={styles['option_main_container']}>
                        <div className={styles['option_text_container']}>
                            <h2>Cutting edge</h2>
                            <p>At Magyk, we pride ourselves on being the most up-to-date agency around. We know technology is changing faster than ever and harness that innovation with tools such as AWS, Snowflake, Redshift and Neo4J to solve business problems in ways never before possible. </p>
                        </div>
                        <div className={styles['option_img_container']}>
                            <img src={cutting_edge} />
                        </div>
                    </div>
                    <div className={styles['option_main_container']}>
                        <div className={styles['option_text_container']}>
                            <h2>Flexible</h2>
                            <p>Whether you are looking for project-based work or long-term staffing, Magyk has a developer to fit your needs. Hire just the developer you need, or hire Magyk's entire team to tackle every aspect of your project with ease and expertise. </p>
                        </div>
                        <div className={styles['option_img_container']}>
                            <img src={flexible} />
                        </div>
                    </div>
                </section>
                <section className={styles.what_we_do}>
                    <div>
                        <h2>Are you looking for something different? find out about more of our services</h2>
                    </div>
                    <div className={styles.services_container}>
                        <div className={styles.services}>
                            <Link to={"/services/websites"} className={styles.service}>
                                <h3>Websites</h3>
                                <img src={websites} alt={"Websites"} />
                            </Link>
                        </div>
                        <div className={styles.services}>
                            <Link to={"/services/engagement"} className={styles.service}>
                                <h3>Engagement</h3>
                                <img src={engagement} alt={"Engagement"} />
                            </Link>
                        </div>
                        <div className={styles.services}>
                            <Link to={"/services/marketing"} className={styles.service}>
                                <h3>Marketing</h3>
                                <img src={marketers} alt={"Marketing"} />
                            </Link>
                        </div>
                    </div>
                </section>
                <section className={styles.contact_container}>
                    <ContactForm />
                </section>
            </div>
        );
    }
}

export default StaffingPageContent;
